body {
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5em;
}

.page-wrapper {
	color: #333333;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5em;
}

h1 {
	color: #283f6f;
	padding-top: 0.75rem;
	background-size: 70px 2px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	line-height: 3rem;
	font-size: 2.6rem;
}

h2 {
	margin-top: 2rem;
	margin-bottom: 0.75rem;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
}

h3,
.inside-right-sidebar-column h4 {
	margin-top: 2rem;
	margin-bottom: 0.75rem;
	background-size: 35px 2px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}

h4 {
	margin-top: 1.875rem;
	margin-bottom: 0.75rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.375rem;
	line-height: 1.875rem;
	font-weight: 400;
}

.inside-right-sidebar-column h4,
.inside-right-sidebar-column h3 {
	font-size: 1.25rem;
	line-height: 1.625rem;
	margin-top: 0rem;
	color: #283f6f;
}

h5 {
	margin-top: 1.75rem;
	margin-bottom: 0.75rem;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	color: #283f6f;
}

h6 {
	margin-top: 1.625rem;
	margin-bottom: 0.75rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-weight: 400;
}

p {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
}

.button,
.primary,
.secondary,
.tertiary,
.inside-content-mine button[type="submit"],
#jobs_module.cms_form .cms_form_button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.625em 1.25em;
	border-radius: 4px;
	background-color: #283f6f;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 0.875rem;
	line-height: 1.25em;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
	color: white !important;
	box-shadow: none;
}

.primary,
.secondary,
.tertiary,
.inside-content-mine button[type="submit"] {
	padding: 0.625em 1.25em !important;
	border: 0 none !important;
	box-shadow: none !important;
}

.button:hover {
	background-color: #1b234c;
	color: #fdc763 !important;
}

.primary:hover,
.inside-content-mine button[type="submit"]:hover {
	background-color: #1b234c !important;
	color: #fdc763 !important;
}

.button.secondary-button,
.secondary {
	margin-right: 1rem;
	margin-left: 1rem;
	background-color: #007dc3;
	color: #ffffff !important;
}

.button.secondary-button:hover {
	background-color: #9b5ba4;
	color: #ffffff !important;
}

.secondary:hover,
.tertiary:hover {
	background-color: #9b5ba4 !important;
	color: #ffffff !important;
}

.footer-section {
	padding-top: 3rem;
	padding-bottom: 1rem;
	background-color: #283f6f;
	color: #e1e8ea;
	font-size: 0.875rem;
	position: relative;
}

.nav-bar-section {
	position: absolute;
	width: 100%;
	padding-top: 14px;
	padding-bottom: 1.3rem;
	border-top: 4px solid #cad4de;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease 0s;
}

.logo-brand-wrap {
	min-width: 0px;
	text-decoration: none;
}

.nav-link.two-part-button-link {
	position: relative;
	z-index: 2;
	display: block;
	width: 80%;
	max-width: 80%;
	float: left;
}

.nav-link.dropdown-list-link:hover {
	color: white;
	text-decoration: none;
}

.nav-link.nav-dropdown-list-link {
	padding-right: 0.75rem;
	padding-left: 0.625rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	float: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	color: #cad4de;
	font-size: 1rem;
	line-height: 1.375em;
	text-decoration: none;
}

.nav-link.nav-dropdown-list-link:hover {
	background-color: rgba(0, 0, 0, 0.14);
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	border-left: 3px none transparent;
	box-shadow: none;
	color: #fff;
}

.nav-link.nav-dropdown-list-link.w--current {
	background-color: rgba(0, 0, 0, 0.15);
	border-left: 3px none transparent;
	box-shadow: none;
	color: #fff;
}

.nav-dropdown {
	position: relative;
	margin-right: 2px;
	margin-left: 2px;
	float: left;
}

.nav-dropdown-label-text {
	position: relative;
	margin-right: 0rem;
	float: left;
}

.nav-two-part-button-wrap {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.nav-two-part-button-dropdown-wrap {
	position: relative;
	left: 0px;
	top: 0px;
	z-index: 1;
	width: 100%;
}

.nav-two-part-button-dropdown-toggle {
	width: 20%;
	max-width: 20%;
	padding: 0.75rem 0px;
	float: right;
	border-left: 1px solid rgba(255, 255, 255, 0.09);
	text-align: center;
}

.nav-two-part-button-icon {
	display: block;
	width: 8px;
	height: 1.25rem;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.125rem;
	opacity: 0.5;
	float: right;
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translate(0px, -50%);
}

.nav-two-part-button-dropdown-list {
	position: relative;
	left: 0px;
	top: 0px;
	float: left;
}

.nav-two-part-button-dropdown-list.w--open {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
}

.nav-link {
	padding: 0rem;
	float: left;
	font-family: 'Roboto Condensed', sans-serif;
	color: #002a54;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.nav-link:hover {
	color: #c7171e;
}

.navigation-link {
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-decoration: none;
	display: block;
	padding: 0.75rem 0.75rem;
}

.spanish .navigation-link {
	text-align: center;
}

.navigation-link.short {
	padding: 1.38rem 0.75rem;
}

.navigation-link:hover,
.nav-link.active .navigation-link,
.navigation-link.active-item {
	color: #9b5ba4;
	border-bottom: 2px solid #9b5ba4;
}

.nav-link.active {
	box-shadow: none;
}

.nav-dropdown-list {
	position: absolute;
	top: 44px;
	z-index: 100;
	min-width: 200px;
	border-top: 3px solid #c7171e;
	background-color: #0f3050;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
}

.logo-image {
	width: 220px;
	margin-top: -2px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.link,
.inside-main-content-column a,
.inside-main-content-column p a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #283f6f;
	text-decoration: none;
	cursor: pointer;
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
}

.link:hover,
.inside-main-content-column a:hover {
	color: #9b5ba4;
}

.inside-main-content-column .inside-content-mine a {
	text-decoration: none;
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
}

.inside-main-content-column .inside-content-mine a:hover {
	border-bottom-color: #72aecc;
}

.inside-main-content-column .inside-content-mine h1 a,
.inside-main-content-column .inside-content-mine h2 a,
.inside-main-content-column .inside-content-mine h3 a,
.inside-main-content-column .inside-content-mine h4 a,
.inside-main-content-column .inside-content-mine h5 a,
.inside-main-content-column .inside-content-mine h6 a {
	text-decoration: none;
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
}

.link.breadcrumb-link {
	display: inline-block;
	float: left;
	color: #283f6f;
	border-bottom: none;
}

.link.breadcrumb-link:hover {
	color: #9b5ba4;
}

.inside-main-content-column .inside-content-mine .link.breadcrumb-link {
	text-decoration: none;
	border-bottom-color: transparent;
}

.inside-main-content-column .inside-content-mine .link.breadcrumb-link:hover {
	text-decoration: none;
	border-bottom-color: #72aecc;
}

.link.footer-link {
	color: white;
	border-bottom: 0px;
}

.link.footer-link:hover {
	color: #a5dd68;
	border-bottom: 0px;
}

.link.content-box-link {
	color: #283f6f;
	border-bottom: 0px;
}

.link.content-box-link:hover {
	color: #9b5ba4;
	border-bottom: 0px;
}

.list,
.inside-main-content-column ol,
.inside-main-content-column ul:not(.breadcrumbs) {
	margin-top: 0.375em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

.breadcrumbs-column {
	height: 40px;
}

.breadcrumbs {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding-left: 1px;
	font-family: 'Roboto Condensed', sans-serif;
	color: #5c7a94;
	font-size: 0.75rem;
	line-height: 1.25em;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.breadcrumb-item {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.breadcrumb-item-divider {
	margin-left: 0.5rem;
	float: right;
}

.split-50 {
	width: 50%;
	float: left;
}

.column.tools {
	padding-right: 0px;
	padding-left: 0px;
}

.row.middle-content-box-row {
	margin-top: 3rem;
	margin-bottom: 3rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 1px solid #e1e8ea;
	border-bottom: 1px solid #e1e8ea;
}

.footer-section-signoff {
	padding-bottom: 1.5rem;
}

.footer-section-signoff .center-column {
	text-align: center;
}

.footer-signoff-grip {
	width: 130px;
	height: 32px;
	float: right;
	opacity: 0.75;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.footer-signoff-grip:hover {
	opacity: 1;
}

#GRIPFooterLogo {
	width: 130px;
	height: 32px;
	float: right;
	opacity: 0.75;
	padding-top: 0 !important;
	margin-top: -4px;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

#GRIPFooterLogoText {
	margin-bottom: -11px;
	color: #ffffff !important;
}

.copyright-text {
	display: inline-block;
	margin-top: 0.5rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #e1e8ea;
	font-size: 0.75rem;
	line-height: 1rem;
}

.footer-list {
	border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-list-item {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.footer-list-link {
	display: block;
	padding: 0.875em 1rem;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_blue.svg');
	background-position: 0px 50%;
	background-size: 0.5rem 0.5rem;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: Roboto, sans-serif;
	color: #ffffff;
	line-height: 1.375em;
	text-decoration: none;
}

.footer-list-link:hover {
	color: #a5dd68;
}

.footer-column-title {
	margin-top: 0rem;
	padding-top: 0.75em;
	padding-bottom: 0.5rem;
	background-image: url('/20150810114905/assets/images/green_tab.png');
	background-size: 50px 2px;
	background-repeat: no-repeat;
	color: white;
	font-weight: 400;
}

.footer-section .social-links {
	border-top: 1px solid #e1e8ea;
	border-bottom: 1px solid #e1e8ea;
	margin-bottom: 45px;
	display: block;
	width: 100%;
	text-align: center;
	padding-bottom: 10px;
	padding-top: 10px;
	max-width: 940px;
	margin-left: auto;
	margin-right: auto;
}

.footer-section .social-links ul {
	list-style-type: none;
	margin: 0px auto;
	padding: 0px;
}

.footer-section .social-links ul li {
	display: inline-block;
	margin: 0px;
	padding: 0px;
}

.footer-section .social-links ul li a {
	margin: 2vw;
}

.footer-section .social-links ul li a:active {
	text-decoration: none;
}

.footer-section .social-links ul li img {
	margin-right: 0px;
	margin-left: 0px;
	width: 36px;
	height: 36px;
	background-color: #fff;
}

.slider-section {
	padding-top: 110px;
	overflow: hidden;
}

.slider {
	min-height: 500px;
	background-color: transparent;
	height: auto;
	overflow: hidden;
}

.w-slider-mask {
	min-height: 500px;
}

.slide-image {
	height: 500px;
}

.slide-caption-container {
	min-height: 500px;
}

.slider-arrow {
	height: 20%;
	opacity: 0.5;
	-webkit-transition: opacity 200ms ease;
	transition: all 500ms ease;
	font-size: 2.25rem;
	color: #6e7c8a;
}

.slider-arrow:hover {
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0.8;
	color: white;
}

.slider-indicators {
	height: 2rem;
	opacity: 1;
	font-size: 0.625rem;
}

.slider-slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	height: auto;
}

.cycle-slide {
	display: flex !important;
}

.slider-slide.slide-2 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-135087111.jpg');
}

.slider-slide.slide-3 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-178923149_crop.jpg');
}

.content-box-section {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.content-box-section a {
	color: #283f6f;
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
	text-decoration: none;
	-webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease !important;
	transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease !important;
}

.content-box-section a:hover {
	color: #9b5ba4;
	text-decoration: none;
}

.content-box-section p a {
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
}

.content-box-section p a:hover {
	border-bottom-color: #72aecc;
}

.content-box-section h3:not(.content-box-title) a {
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
}

.content-box-section h3:not(.content-box-title) a:hover {
	border-bottom-color: #72aecc;
}

.content-box-section ul {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

.content-box-section ol {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

.content-box-section li {
	margin-bottom: 0.25em;
}

.content-box-section li a {
	border-bottom: 1px solid;
	border-bottom-color: #72aecc;
	-webkit-transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease;
	transition: color 200ms ease, background-color 200ms ease, border 200ms ease, box-shadow 300ms ease;
}

.content-box-section li a:hover {
	border-bottom-color: #72aecc;
}

.content-box-wrap {
	font-size: 0.875rem;
	line-height: 1.5em;
}

.content-box-wrap .button,
.our-company-section-content-wrap .button {
	background: #283f6f;
	color: #fff !important;
}

.content-box-wrap .button:hover,
.our-company-section-content-wrap .button:hover {
	background: #1b2e4c;
	color: #fdc763 !important;
}

.content-box-image {
	width: 36%;
	min-height: 115px;
	float: left;
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition-delay: initial;
	transition-delay: initial;
}

.content-box-image:hover {
	opacity: 1;
}

.content-box-image.businesses {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-162631615_edit_768.jpg');
}

.content-box-image.individuals {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-57568504_768.jpg');
}

.content-box-text-wrap {
	width: 60%;
	padding-right: 1rem;
	float: right;
}

.cb-list h3,
.content-box-title {
	margin-top: 0.5rem;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.tools-section {
	margin-top: 0.25rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #fdc763;
	background-image: url('/20150810114905/assets/images/little-pluses.png');
}

.home-tools-link-wrap {
	position: relative;
	display: block;
	width: 95%;
	margin-right: auto;
	margin-left: auto;
	background-color: #e1e8ea;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_red.svg');
	background-position: 95% 52%;
	background-size: 0.875rem 0.875rem;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.5rem;
	line-height: 1.25em;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.home-tools-link-wrap:hover {
	background-color: #fff;
	color: #283f6f;
}

.home-tools-link-image {
	position: relative;
	width: 4.5rem;
	height: 4.5rem;
	float: left;
	background-color: #007dc3;
	background-position: 50% 50%;
	background-size: 70% 70%;
	background-repeat: no-repeat;
	border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.home-tools-link-image.proposal {
	background-image: url('/20150810114905/assets/images/events-and-presentations-icon.svg');
}

.home-tools-link-image.claim-icon {
	background-image: url('/20150810114905/assets/images/schedule-an-appointment-icon.svg');
}

.home-tools-link-image.tools-icon {
	background-image: url('/20150810114905/assets/images/curriculum-icon.svg');
}

.home-tools-link-text {
	position: absolute;
	left: 5.5rem;
	top: 50%;
	float: left;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	text-transform: none;
}

.specialty-programs-section-list,
.cb-list ul {
	margin-top: 3.5rem;
	padding-left: 0;
}

.specialty-programs-list-item,
.cb-list li {
	display: inline-block;
	width: 44%;
	margin-right: 3%;
	margin-left: 3%;
	float: left;
	border-bottom: 1px solid rgba(0, 42, 84, 0.14);
	text-align: left;
}

.specialty-programs-list-link,
.cb-list li a {
	display: block;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 0.875rem;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_red.svg');
	background-position: 2px 53%;
	background-size: 0.5rem 0.5rem;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #002a54;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.specialty-programs-list-link:hover,
.cb-list li a:hover {
	color: #b8131b;
}

.home-tools-row {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.our-company-section {
	position: relative;
	height: auto;
	padding-top: 4rem;
	padding-bottom: 4rem;
	margin-bottom: 0.25rem;
	background-position: 50% 0px;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.our-company-section-content-wrap {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	display: block;
	width: 85%;
	max-width: 940px;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 4rem;
	background-color: rgba(36, 112, 153, 0.7);
	background-image: url('/20150810114905/assets/images/navy.png');
	color: #fff;
	text-align: center;
}

.our-company-section-title {
	margin-top: 0.75rem;
	padding-top: 1rem;
	background-image: url('/20150810114905/assets/images/yellow_tab.png');
	background-position: 50% 0px;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	color: white;
}

.our-company-section-text {
	font-size: 1.125rem;
	line-height: 2rem;
}

.slider-content-overlay-wrap {
	max-width: 728px;
	margin-right: auto;
	margin-left: auto;
	color: #ffffff;
	text-align: left;
}

.slider-content-overlay-wrap {
	padding: 1.5rem 4rem;
	background-color: rgba(41, 106, 171, 0.88);
	background-image: url('/20150810114905/assets/images/navy.png');
}

.fixed-bknd-section-texture {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	margin-bottom: 0rem;
	background-attachment: fixed;
	opacity: 0.5;
	background-image: -webkit-linear-gradient(rgba(0, 42, 84, 0.8), rgba(0, 42, 84, 0.8));
	background-image: linear-gradient(rgba(0, 42, 84, 0.8), rgba(0, 42, 84, 0.8));
}

.slider-content-overlay-wrap .button {
	background: #283f6f;
	color: #fff !important;
}

.slider-content-overlay-wrap .button:hover {
	background: #1b2e4c;
	color: #fdc763 !important;
}

.slider-title {
	margin-top: 0.75rem;
	padding-top: 1rem;
	background-image: url('/20150810114905/assets/images/green_tab.png');
	background-position: 0 0px;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	color: white;
	font-weight: 400;
	text-transform: uppercase;
}

.slider-text {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
}

.slider-text a {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
	color: #fff;
}

.slider-text h1,
.slider-text h2,
.slider-text h3,
.slider-text h4,
.slider-text h5,
.slider-text h6 {
	color: white;
	margin-bottom: 0.2rem;
	margin-top: 0.2rem;
	font-weight: 400;
}

.footer-icon {
	width: 3rem;
	height: 3rem;
	margin-top: 1rem;
	margin-bottom: 0.25rem;
}

.content-box-image-link {
	width: 100%;
	height: 200px;
	margin-bottom: 0.5rem;
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.content-box-image-link.image-1 {
	background-image: url('/20150810114905/assets/images/Bond-picture_edit.jpg');
}

.content-box-image-link.image-2 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-177792762_edit_768.jpg');
}

.content-box-image-link.image-3 {
	background-image: url('/20150810114905/assets/images/ThinkstockPhotos-465065737_768.jpg');
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	opacity: 1;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #ffffff;
	font-size: 0.75rem;
	line-height: 1rem;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #a5dd68;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-left: 0.75rem;
}

.footer-quick-links-wrap {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.nav-search-icon-btn {
	width: 3.25rem;
	height: 3rem;
	min-width: 0px;
	margin-right: 0rem;
	margin-left: 0.5rem;
	float: right;
	border-left: 0px solid rgba(0, 42, 84, 0.25);
	background-image: url('/20150810114905/assets/images/search_blk.svg');
	background-position: 50% 50%;
	background-size: 1rem 1rem;
	background-repeat: no-repeat;
	opacity: 0.35;
	-webkit-transition: opacity 400ms ease !important;
	transition: opacity 400ms ease !important;
}

.nav-search-icon-btn:hover {
	opacity: 1;
}

.header-search-wrap {
	position: relative;
	z-index: 90;
	display: none;
	width: 100%;
	margin: 0.5rem auto -1rem;
	padding-top: 0.5rem;
	float: left;
	clear: both;
	transition: all 500ms ease 0s;
}

.header-search-field {
	width: 85% !important;
	height: 3rem;
	margin-bottom: 0px;
	padding: 0.25rem 0.75rem;
	float: left;
	border-style: solid none solid solid;
	border-width: 1px;
	border-color: rgba(0, 42, 84, 0.09);
	border-radius: 0px;
	font-size: 1.125rem;
}

.header-search-form-button {
	width: 15%;
	height: 3rem;
	min-width: 0px;
	float: left;
	background-color: #fdc763;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_wht_50.svg');
	background-position: 50% 50%;
	background-size: 1.25rem;
	background-repeat: no-repeat;
	border: 0 none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-size: 0;
}

.header-search-form-button:hover {
	background-color: #ffd89f;
}

.header-search-session {
	height: 0px;
	background-color: transparent;
}

.content-box-learn-more-link {
	display: inline-block;
	margin-top: 0.5em;
	padding-left: 0.875em;
	background-image: url('/20150810114905/assets/images/icon_arrow2_right_red.svg');
	background-position: 0px 50%;
	background-size: 0.625em 0.625em;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', sans-serif;
	color: #9b5ba4 !important;
	font-size: 0.9375rem;
	line-height: 1.25em;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 1px solid;
	border-bottom-color: transparent;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	border-bottom: 0px !important;
}

.content-box-learn-more-link:hover {
	border-bottom-color: transparent;
	color: #283f6f !important;
}

.footer-nav-list {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	text-align: center;
}

.footer-nav-list-item {
	display: inline-block;
	margin-right: 2rem;
	margin-left: 2rem;
}

.footer-nav-list-link {
	display: block;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #76818c;
	font-size: 1rem;
	text-decoration: none;
}

.footer-nav-list-link:hover {
	color: #69c0ff;
}

.footer-nav-list-link.w--current {
	color: #cad4de;
	font-size: 1rem;
}

.footer-nav-list-link.w--current:hover {
	color: #69c0ff;
}

.inside-content-section {
	background: #e1e8ea;
}

.inside-content-header-section {
	background-color: #283f6f;
	position: relative;
	height: 200px;
}

.left-nav-list.left-nav-nested-list {
	padding-bottom: 2rem;
	padding-left: 0.625rem;
	box-shadow: rgba(0, 42, 84, 0.1) 0px -1px 0px 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.left-nav-list-item {
	margin-top: 1px;
	margin-bottom: 1px;
	box-shadow: rgba(0, 42, 84, 0.09) 0px 1px 0px 0px;
}

.left-nav-list.left-nav-nested-list ul {
	padding-bottom: 0.7rem;
}

.left-nav-link {
	display: block;
	padding: 0.625rem 1rem 0.625rem 0.75rem;
	box-shadow: transparent 3px 0px 0px 0px inset;
	-webkit-transition: color 200ms ease, box-shadow 200ms ease;
	transition: color 200ms ease, box-shadow 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.125rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.left-nav-link:hover {
	box-shadow: #fdc763 3px 0px 0px 0px inset;
	color: #283f6f;
	background-color: #e1e8e9;
}

.left-nav-link:active {
	color: #283f6f;
}

.left-nav-link.w--current,
.left-nav-link.active {
	background-color: #e1e8e9;
	box-shadow: #fdc763 3px 0px 0px 0px inset;
}

.left-nav-link.nested-nav-link {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #283f6f;
	font-size: 1rem;
	line-height: 1.25rem;
}

.left-nav-link.nested-nav-link:hover {
	color: #283f6f;
	background: #e1e8e9;
}

.left-nav-link.nested-nav-link.w--current {
	background: #e1e8e9;
	color: #283f6f;
}

.left-nav-list-title-link-wrap {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	margin-left: 0.625rem;
	padding-top: 0.75em;
	padding-bottom: 0.75rem;
	padding-right: 0.625rem;
	background-image: url('/20150810114905/assets/images/yellow_tab.png');
	background-position: 0px 0px;
	background-size: 50px 2px;
	background-repeat: no-repeat;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #283f6f;
	font-size: 1.375rem;
	line-height: 1.625rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-list-title-link-wrap:hover {
	color: #283f6f;
}

.left-nav-list-title {
	margin-top: 0rem;
	margin-bottom: 0rem;
}

.form-wrap {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.form-field-label {
	margin-bottom: 2px;
	font-family: 'Roboto Condensed', sans-serif;
	color: #002a54;
	font-weight: 400;
	text-transform: uppercase;
}

.form-text-field {
	margin-bottom: 16px;
	padding-right: 10px;
	padding-left: 10px;
}

.form-text-field.right-sidebar-search-field {
	height: 34px;
	margin-bottom: 0px;
}

.form-success {
	padding-top: 16px;
	background-color: #606f1e;
	color: #ffffff;
	font-size: 1.25rem;
	line-height: 1.75rem;
	text-align: center;
}

.form-error {
	background: #b80b0b;
	color: white;
}

.left-nav-column-wrap {
	padding-top: 1.5rem;
	padding-bottom: 6rem;
	border-right: 1px solid rgba(0, 42, 84, 0.1);
}

.inside-right-sidebar-column {
	border-left: 1px solid rgba(0, 42, 84, 0.11);
}

.inside-main-content-nested-column-content-wrap {
	margin-right: 1.25rem;
}

.inside-right-sidebar-section-wrap {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	margin-left: 1.25rem;
}

.inside-right-sidebar-section-title {
	margin-top: 0rem;
	font-size: 1.25rem;
	line-height: 1.625rem;
}

.inside-right-sidebar-list-item {
	margin-bottom: 0.75rem;
}

.inside-content-bknd {
	display: block;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 4rem;
	background-color: white;
	min-height: 650px;
	position: relative;
	padding-top: 0em;
	box-shadow: #cad4de 0 -4px 0 0;
}

.inside-content-bknd.widthBanner {
	margin-top: -55px;
	box-shadow: none;
}

.header-content-wrap {
	position: relative;
	z-index: 100;
}

.footer-logos-wrap {
	width: 25%;
	float: left;
}

.w-row.footer-links {
	margin-top: 1.5rem;
	padding-top: 1.5rem;
}

@media (max-width: 991px) {
	.footer-section {
		padding: 2.5rem 1rem 1rem;
		font-size: 1.125rem;
		line-height: 2.25rem;
	}

	.nav-bar-section {
		padding-top: 0rem;
		padding-bottom: 0rem;
		position: relative;
	}

	.nav-bar-container {
		max-width: 100%;
		margin-right: 0px;
		margin-left: 0px;
	}

	.logo-brand-wrap {
		margin: 0.5rem;
	}

	.nav-menu-wrap {
		padding-bottom: 30rem;
	}

	.nav-link.two-part-button-link {
		border-bottom-style: none;
	}

	.nav-link.two-part-button-link:hover {
		box-shadow: #9fb833 5px 0px 0px 0px inset;
	}

	.nav-link.dropdown-list-link {
		overflow-x: hidden;
		overflow-y: hidden;
		max-width: 100%;
	}

	.nav-link.dropdown-list-link:hover {
		box-shadow: #9fb833 5px 0px 0px 0px inset;
	}

	.nav-two-part-button-wrap {
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		border-bottom: 1px solid rgba(255, 255, 255, 0.09);
	}

	.nav-two-part-button-dropdown-wrap {
		position: relative;
	}

	.nav-two-part-button-dropdown-toggle {
		min-height: 100%;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.nav-two-part-button-dropdown-toggle:hover {
		background-color: rgba(0, 0, 0, 0.09);
	}

	.nav-two-part-button-dropdown-toggle.w--open {
		background-color: rgba(0, 0, 0, 0.2);
		padding-top: 1.5rem;
	}

	.nav-two-part-button-dropdown-toggle.w--open:hover {
		background-color: rgba(0, 0, 0, 0.29);
		padding-top: 1.5rem;
	}

	.nav-two-part-button-icon {
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translate(0px, -50%);
	}

	.nav-two-part-button-dropdown-list.w--open {
		max-width: 100%;
		border-top: 1px solid rgba(255, 255, 255, 0.09);
		background-color: rgba(0, 0, 0, 0.2);
	}

	.nav-link {
		display: block;
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0px;
		float: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		color: #cad4de;
		font-size: 1.125rem;
		font-weight: 300;
		letter-spacing: 1px;
	}

	.nav-link:hover {
		box-shadow: none;
		color: #e31822;
	}

	.nav-link.w--current {
		background-color: rgba(0, 0, 0, 0.29);
		box-shadow: #9fb833 5px 0px 0px 0px inset;
		color: white;
	}

	.nav-menu-button {
		padding: 1.25rem 1.25rem 1rem;
		border-left: 1px solid rgba(0, 42, 84, 0.1);
		font-family: 'Roboto Condensed', sans-serif;
		color: #283f6f;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		height: 90px;
		margin-top: 15px;
	}

	.nav-menu-button:hover {
		background-color: #e1e8ea;
		box-shadow: rgba(0, 0, 0, 0.25) 0px -4px 0px 0px;
		color: #283f6f;
	}

	.mm-wrapper_opened .mobile-menu-button {
		background-color: #e1e8ea;
		box-shadow: rgba(0, 0, 0, 0.25) 0px -4px 0px 0px;
		color: #283f6f;
	}


	.nav-menu-button-icon {
		width: 1.25rem;
		height: 1.375rem;
		opacity: 0.25;
	}

	.breadcrumb-item {
		margin-right: 0.75rem;
	}

	.breadcrumb-item-divider {
		margin-left: 0.75rem;
	}

	.column.footer-column {
		margin-bottom: 3rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid rgba(255, 255, 255, 0.09);
	}

	.footer-list-link {
		line-height: 1.5rem;
	}

	.footer-column-title {
		font-size: 1.5rem;
	}

	#GRIPFooterLogoText {
		margin-bottom: -25px;
	}

	.slider-section {
		padding-top: 0px;
	}

	.slider {
		min-height: 440px;
	}

	.w-slider-mask {
		min-height: 440px;
	}

	.slide-image {
		height: 440px;
	}

	.slide-caption-container {
		min-height: 440px;
	}

	.slider-arrow {
		width: 7%;
	}

	.content-box-section {
		padding-top: 1rem;
		padding-bottom: 3rem;
	}

	.content-box-wrap {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.content-box-image {
		width: 45%;
		min-height: 200px;
		float: right;
	}

	.content-box-text-wrap {
		width: 52%;
		margin-top: 0.5rem;
		float: left;
	}

	.tools-section {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.home-tools-link-wrap {
		background-size: 0.75rem 0.75rem;
		font-size: 1.375rem;
		line-height: 1.25em;
	}

	.specialty-programs-section-list,
	.cb-list ul {
		display: block;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
	}

	.specialty-programs-list-item,
	.cb-list li {
		width: 47%;
		margin-left: 0%;
	}

	.specialty-programs-row {
		margin-top: 0rem;
		padding-top: 0rem;
		border-top-style: none;
	}

	.slider-content-overlay-wrap {
		padding: 1rem 2rem;
	}

	.slider-title {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.slider-text {
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.content-box-image-link {
		width: 45%;
		float: left;
	}

	.content-box-image-link.image-1 {
		float: left;
	}

	.middle-content-box-text-wrap {
		width: 50%;
		margin-top: 1rem;
		margin-left: 3%;
		float: left;
	}

	.footer-quick-links-logo-link {
		margin-right: 2.25rem;
	}

	.footer-telephone-link {
		display: inline !important;
		color: #e1e8ea !important;
	}

	.nav-search-icon-btn {
		width: 3rem;
		height: 90px;
		margin-left: 0rem;
		border-left-style: none;
		background-size: 1.25rem 1.25rem;
		margin-top: 3px;
	}

	.header-search-wrap {
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding: 0.75rem;
		border-top: 1px solid rgba(0, 42, 84, 0.09);
		overflow: hidden;
	}

	.footer-nav-list-item {
		margin-right: 1rem;
		margin-left: 1rem;
		line-height: 1.5rem;
	}

	.footer-nav-list-link {
		font-size: 1rem;
	}

	.inside-right-sidebar-column {
		margin-top: 4rem;
		padding-top: 2rem;
		border-top: 1px solid rgba(0, 42, 84, 0.11);
		border-left-style: none;
		border-left-width: 0px;
	}

	.inside-main-content-nested-column-content-wrap {
		margin-right: 0rem;
	}

	.inside-right-sidebar-section-wrap {
		margin-left: 0rem;
	}

	.inside-content-bknd {
		max-width: 840px;
	}

	.inside-content-header-section {
		height: 100px;
	}
}

@media (max-width: 991px) {
	.footer-quick-links-wrap {
		margin-left: 0px;
		padding-right: 0px;
		width: 100%;
	}

	.footer-column-title.quick-links {
		margin-left: 0px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.125rem;
	}

	.footer-section {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.logo-brand-wrap {
		padding-left: 0px;
	}

	.nav-menu-button {
		padding-right: 1.125rem;
		padding-left: 1.125rem;
	}

	.column.tools {
		padding: 0.25rem 1.25rem;
	}

	.footer-section-signoff {
		margin-top: 0rem;
		text-align: center;
	}

	.footer-signoff-grip {
		margin-top: 2rem;
		margin-right: 0rem;
		float: none;
	}

	#GRIPFooterLogo {
		float: none;
		margin: 2rem auto 0;
	}

	.slider {
		min-height: 400px;
	}

	.w-slider-mask {
		min-height: 400px;
	}

	.slide-image {
		height: 400px;
	}

	.slide-caption-container {
		min-height: 400px;
	}

	.slider-arrow {
		display: none;
	}

	.content-box-section {
		padding-right: 0.625rem;
		padding-left: 0.625rem;
	}

	.content-box-wrap {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.content-box-image {
		width: 100%;
		min-height: 280px;
		float: left;
	}

	.content-box-text-wrap {
		width: 100%;
		margin-top: 0.75rem;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.tools-section {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.home-tools-link-wrap {
		width: 100%;
		line-height: 1.25rem;
	}

	.specialty-programs-list-item,
	.cb-list li {
		display: block;
		width: auto;
		margin-right: 0%;
		margin-left: 0%;
		float: none;
	}

	.our-company-section-content-wrap {
		width: 95%;
		padding-right: 25px;
		padding-left: 25px;
	}

	.our-company-section-title {
		font-size: 1.5rem;
		line-height: 1.875rem;
	}

	.our-company-section-text {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.slider-content-overlay-wrap {
		width: 100%;
	}

	.content-box-image-link {
		width: 100%;
		height: 280px;
		float: none;
	}

	.middle-content-box-text-wrap {
		width: 100%;
		margin-top: 0rem;
		margin-left: 0%;
		float: none;
		font-size: 1rem;
	}

	.footer-signoff-link {
		font-size: 0.875rem;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-left: 0rem;
	}

	.footer-quick-links-wrap {
		width: 100%;
		padding-right: 0rem;
		padding-bottom: 0rem;
		float: none;
		clear: both;
		border-right-style: none;
	}

	.footer-quick-links-logo-link {
		display: inline-block;
		margin-top: 2rem;
		margin-right: 1rem;
		margin-left: 1rem;
		float: none;
	}

	.footer-link-logo-image.risk-pro-net {
		float: none;
	}

	.footer-nav-list-item {
		display: block;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		float: none;
	}

	.footer-nav-list-link {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.inside-main-content-column {
		padding-right: 20px;
		padding-left: 20px;
	}

	.footer-logos-wrap {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		float: none;
		text-align: center;
	}

	.social-links {
		margin-left: 10px;
		margin-right: 10px;
		width: auto;
	}

	.footer-section .social-links ul li img {
		width: 26px;
		height: 26px;
	}

	.footer-section .social-links ul li a {
		margin: 2.6vw;
	}
}

@media (max-width: 479px) {
	.logo-image {
		width: 176px;
	}

	.footer-section {
		padding-right: 0.375rem;
		padding-left: 0.375rem;
	}

	.breadcrumbs {
		margin-top: 1rem;
	}

	.split-50.footer-split {
		width: 100%;
	}

	.column.tools {
		padding-right: 0.8756rem;
		padding-left: 0.875rem;
	}

	.footer-list {
		margin-right: 0rem;
	}

	.slider {
		min-height: 320px;
	}

	.w-slider-mask {
		min-height: 320px;
	}

	.slide-image {
		height: 320px;
	}

	.slide-caption-container {
		min-height: 320px;
	}

	.content-box-section {
		padding-right: 0.375rem;
		padding-left: 0.375rem;
	}

	.content-box-image {
		min-height: 180px;
	}

	.content-box-text-wrap {
		width: 100%;
		float: left;
	}

	.specialty-programs-list-item,
	.cb-list li {
		margin-right: 0%;
		margin-left: 0%;
	}

	.our-company-section-content-wrap {
		width: 90%;
	}

	.content-box-image-link {
		height: 180px;
	}

	.header-search-field {
		width: 80% !important;
	}

	.header-search-form-button {
		width: 20%;
	}

	.inside-main-content-column {
		padding-right: 16px;
		padding-left: 16px;
	}

	.nav-search-icon-btn {
		background-size: 1.1rem;
		margin-top: -4px;
	}

	.nav-menu-button {
		height: 80px;
		margin-top: 10px;
	}
}

html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

a[href^=tel] {
	color: #ffffff;
	text-decoration: none;
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/* Tablesaw */
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: 0px solid;
	}
}

/* Print */
#printHeader,
#printFooter {
	display: none;
}

/* Header */
#ecommerceLinks {
	position: static;
	margin-top: 0px;
	float: right;
}

#ecommerceLinks a {
	margin-left: 1em;
	float: left;
}

#ecommerceLinks a:hover .ecommerceLinkText {
	color: #283f6f;
}

#ecommerceLinks a:hover .ecommerceLinkImg {
	opacity: 0.7;
}

.ecommerceLinkText {
	float: left;
	font-weight: 700;
	font-size: 0.75rem;
	text-align: right;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 0.75rem;
	color: #9b5ba4;
	margin-top: 3px;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.ecommerceLinkImg {
	width: 1em;
	height: 1em;
	margin-right: 0.5em;
	float: left;
	opacity: 0.35;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.nav-link.nav-dropdown-list-link.nav-section-header {
	border-bottom-width: 2px;
	border-left-style: none;
	color: rgba(255, 255, 255, 0.55);
	font-size: 1.125rem;
	font-weight: 400;
	letter-spacing: 0.03em;
	line-height: 1.25em;
	text-transform: uppercase;
	padding: 0.625rem 0.5rem;
	text-decoration: none;
}

.nav-link.nav-dropdown-list-link.nav-section-header:hover {
	background-color: transparent;
	color: #ffffff;
}

.nav-dropdown-list.w--open {
	padding-bottom: 0rem;
	padding-top: 0rem;
}

@media (max-width: 991px) {
	#ecommerceLinks {
		display: none;
	}

	#ecommerceLinks a {
		margin-left: 1em;
	}

	.ecommerceLinkText .cartNumber span {
		display: none;
	}

	.ecommerceLinkImg {
		width: 1.275em;
		height: 1.275em;
		opacity: 0.35;
		margin: 0.375em;
	}

	#ecommerceLinks a:hover .ecommerceLinkImg {
		opacity: 1;
	}

	#cart-link {
		position: relative;
	}

	.ecommerceLinkText .cartNumber {
		position: absolute;
		top: 50%;
		left: 65%;
		padding: 3px;
		border-radius: 3px;
		background-color: rgb(33, 95, 140);
		line-height: 0.6875rem;
		font-size: 0.75em;
		color: #fff;
		min-width: 1rem;
		text-align: center;
	}
}

/* Sticky header */
@media screen and (min-width: 992px) {
	.sticky.nav-bar-section {
		position: fixed;
		top: 0;
		padding-top: 1.2rem;
		border-top: 2px solid #cad4de;
		background-color: #ffffff;
		width: 100%;
		border-bottom: 1px solid #cad4de;
	}

	.sticky .ecommercelinks {
		margin-top: -30px;
		max-height: 0;
		opacity: 0;
	}

	.sticky .header-content-wrap {
		margin-top: -9px;
	}

	.sticky .nav-dropdown-list {
		top: 44px;
	}

	.sticky .header-search-wrap {
		float: none;
	}
}

/* Mobile menu */
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #283f6f;
}

.mobile-navigation-menu li {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-navigation-menu li a {
	padding: 22px 5px 23px 20px;
}

.mobile-navigation-menu li a.nav-a {
	color: #fff;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 1.125rem;
	line-height: 1.25rem;
	letter-spacing: 1px;
	font-weight: 300;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 19%;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 19%;
}

.mm-menu .w-nav-link.nav-a:hover,
.mm-menu li.active .w-nav-link.nav-a {
	color: #fff;
	box-shadow: 5px 0 0 0 #fdc763 inset;
	background-color: #1b2e4c;
}

.mm-listview a.mm-btn_next:hover {
	background-color: #1b2e4c;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #fff;
}

.mm-listitem:after {
	border-bottom: 0px;
}

.mm-btn_next:after {
	right: 49%;
}

.mm-navbar {
	border: 1px solid rgba(255, 255, 255, 0.1);
	text-align: left;
	height: 60px;
}

.mm-menu .mm-navbar > * {
	color: #fff !important;
	padding: 20px 0;
	color: #fff;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 300;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: 0px;
}

.mm-btn_prev:before {
	left: 18px;
	top: 16px;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background: none;
}

/* Accesible Menu */
.accesible-navigation-menu-container {
	float: right;
	margin-top: 10px;
}

.accesible-navigation-menu {
	float: right;
}

.accesible-navigation-menu ul {
	margin: 0px;
	list-style-type: none;
	padding-left: 0px;
}

.accesible-navigation-menu li.nav-dropdown .container-wrapper {
	display: none;
	position: absolute;
	top: 44px;
	z-index: 100;
	min-width: 200px;
}

.accesible-navigation-menu .spanish li.nav-dropdown .container-wrapper {
	top: 64px;
}

.accesible-navigation-menu li.nav-dropdown.open .container-wrapper {
	display: block;
}

.accesible-navigation-menu .container-wrapper ul {
	background-color: #283f6f;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
	transition: opacity 300ms;
	opacity: 0;
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.nav-dropdown.open .container-wrapper ul {
	opacity: 1;
}

.accesible-navigation-menu .container-wrapper ul li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu .container-wrapper ul li a {
	padding-right: 0.75rem;
	padding-left: 0.625rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	float: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	color: #ffffff;
	font-size: 1rem;
	line-height: 1.375em;
	text-decoration: none;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu .container-wrapper ul li a:hover,
.accesible-navigation-menu .container-wrapper ul li a.w--current {
	background-color: #ffffff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	border-left: 3px none transparent;
	box-shadow: none;
	color: #283f6f;
}

.accesible-navigation-menu .nav-dropdown.has-dropdown .navigation-link {
	padding-right: 0px;
}

.accesible-navigation-menu .nav-dropdown.has-dropdown .navigation-link:after {
	width: 8px;
	height: 8px;
	display: inline-block;
	content: "";
	opacity: 0.5;
	background-image: url("/20180703073046/assets/images/icon_arrow2_down_blk_50.svg");
}

/* Slideshow */
.slide-image {
	width: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
}

.slide-caption-container {
	margin: 0 auto;
	width: 100%;
	height: auto;
	position: relative;
	max-width: 728px;
	display: flex;
	align-items: center;
}

.w-shadow.cycle-pager {
	position: relative;
	z-index: 900;
	margin-top: -20px;
}

.w-slider-mask {
	height: 100%;
	width: 100%;
	position: relative;
}

@media (min-width: 767px) {
	.slider-content-overlay-wrap {
		position: static;
		left: auto;
		right: auto;
		top: auto;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}
}

@media (max-width: 991px) {
	.slide-caption-container {
		max-width: 728px;
	}
}

@media (max-width: 767px) {
	.slide-caption-container {
		width: 90%;
		height: 100%;
		position: relative;
		max-width: none;
		display: flex;
		align-items: center;
	}
}



/* Inside page */
@media (max-width: 991px) {
	.inside-main-content-nested-column-content-wrap img {
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/* Inside banner */
.inside-top-photo {
	width: 100%;
	height: 250px;
	background-size: cover;
	background-position: 50% 50%;
	border-top: 4px solid #cad4de;
}

@media (max-width: 479px) {
	.inside-top-photo {
		height: 200px;
	}
}



/* Right sidebar */
.inside-right-sidebar-column .box {
	margin-bottom: 1.5em;
}

.inside-right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 7px;
	padding-right: 7px;
}

.inside-right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.inside-right-sidebar-column td img {
	max-width: none;
}

.inside-right-sidebar-column input[type=text] {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #555;
	font-size: 0.875rem;
	line-height: 1.42857;
	padding: 8px 12px;
}

.inside-right-sidebar-column .box a {
	color: #283f6f;
}

.inside-right-sidebar-column .box a:hover {
	color: #9b5ba4;
}

.inside-right-sidebar-column .box a.moduleDocuments-rss-feed-link {
	border-bottom: 0px;
}

@media (max-width: 991px) {
	.inside-right-sidebar-column .box.documents img,
	.inside-right-sidebar-column .box .contentbox_item_image {
		width: 24px;
	}

	.inside-right-sidebar-column input[type=text] {
		width: 100%;
	}
}


/* Inside page styles */
#message.success {
	margin-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #307820;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
	margin-bottom: 20px;
	padding: 10px 20px;
	border-style: solid;
	border-width: 3px 1px 1px;
	border-color: #307820;
	margin-top: 20px;
}

#message.success p {
	font-family: Roboto, sans-serif;
	color: #307820;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

#message.success ul li {
	font-family: Roboto, sans-serif;
	color: #307820;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

#message.error {
	margin-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
	margin-bottom: 20px;
	margin-top: 20px;
	padding: 10px 20px;
	border-style: solid;
	border-width: 2px 1px 1px;
	border-color: #b40805;
}

#message.error p {
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

#message.error ul li {
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 700;
}

table.styled {
	width: 100%;
	margin: 1.5rem 0 1rem 0;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid ghostwhite;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: bold;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: white;
	background-color: #283f6f;
	padding: 0.75rem 10px;
	text-align: left;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.inside-main-content-column .inside-content-mine table.styled thead tr th a,
.inside-main-content-column .inside-content-mine table.styled thead tr th a:hover,
.inside-main-content-column .inside-content-mine table.styled th a,
.inside-main-content-column .inside-content-mine table.styled th a:hover {
	text-decoration: none;
	color: inherit;
	border: 0px;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	padding: 0.5rem 10px;
	background-color: #fdc763;
	color: #283f6f;
	text-align: left;
}

table.styled td {
	padding: 8px 10px;
}

table.styled.striped tr:nth-child(even) {
	background: #e1e8ea;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

.inside-content-mine table.styled input[type=text],
.inside-content-mine table.styled select {
	display: inline;
	margin: 0px;
}

.inside-content-mine table.styled.loanRepaymentCalculator input[type="text"],
.inside-content-mine table.styled.loanRepaymentCalculator select {
	width: 100px !important;
}

table.styled.calculator td {
	border-bottom: 1px solid #eeeeee;
	border-right: 0px;
}

@media screen and (max-width: 991px) {
	.inside-content-mine table.styled input[type="text"],
	.inside-content-mine table.styled select {
		width: auto !important;
	}

	.inside-content-mine table.styled.creditCardRepaymentCalculator input[type="text"],
	.inside-content-mine table.styled.creditCardRepaymentCalculator select {
		width: 50% !important;
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 479px) {
	.inside-content-mine table.styled.loanRepaymentCalculator input[type="text"],
	.inside-content-mine table.styled.loanRepaymentCalculator select {
		width: 80% !important;
	}
}



/* Acrobat link */
.adobe-column {
	display: flex;
	align-items: center;
	min-height: 28px;
}

a.acrobat-reader-link {
	color: #ffffff;
	font-size: 0.85rem;
	text-decoration: none;
	font-family: 'Roboto Condensed', sans-serif;
	margin-top: 0.3rem;
	transition: all ease 250ms;
}

a.acrobat-reader-link:hover {
	color: #a5dd68;
}

@media (max-width: 991px) {
	a.acrobat-reader-link {
		margin-top: 0.05rem;
	}
}

@media (max-width: 767px) {
	.adobe-column {
		justify-content: center;
	}

	a.acrobat-reader-link {
		margin-top: 0;
	}
}

.module-search .inside-content-mine input[type="search"] {
	display: inline-block;
	margin-bottom: 0;
	height: 36px;
}

/* Alert Regions */
/* Pop up alert */
.alert-pop-up-wrapper {
	z-index: 5000;
	width: 100vw;
	height: 100vh;
	background-color: rgba(41, 41, 41, 0.6);
	display: none;
	position: fixed;
	top: 0;
}

.alert-pop-up-section-outer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.alert-pop-up-section {
	width: 600px;
	background-color: #fff;
	border-top: 5px #c5272a;
	border-radius: 0;
	display: block;
}

.decorative-yellow {
	background-color: #fdc763;
	background-image: url("/assets/images/pop-up-alert-bg.png");
	margin-top: 0;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	padding-right: 10px;
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.exit-icon-wrapper {
	z-index: 100;
	cursor: pointer;
	top: 0.5rem;
	bottom: auto;
	left: auto;
	right: 0.5rem;
	background-color: transparent;
	border: 0px;
}

.exit-icon-wrapper .exit-icon {
	opacity: 0.65;
	padding: 12px;
	transition: opacity 0.2s;
}

.alert-pop-up-content-wrapper {
	height: 100%;
	border-top: 4px #fcb31c;
	border-bottom: 5px #fcb31c;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.alert-content {
	min-height: 290px;
	background-image: linear-gradient(rgba(246, 246, 246, 0.98), rgba(246, 246, 246, 0.98)), url("/assets/images/pop-up-alert-bg.png");
	background-position: 0 0, 0 0;
	background-repeat: repeat, repeat;
	background-size: auto, auto;
	border-top: 5px #fcb31c;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 2.15rem;
	padding-right: 2.15rem;
	padding-top: 10px;
	display: flex;
	text-align: center;
}

.alert-content p {
	max-width: 800px;
	text-align: center;
	font-family: Roboto, sans-serif;
	font-size: 1.15rem;
	line-height: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.alert-content h2 {
	font-size: 2.25rem;
	font-weight: 400;
	line-height: 2rem;
	margin-bottom: 1rem;
	font-family: Roboto Condensed, sans-serif;
	margin-top: 0px;
}

@media screen and (max-width: 991px) {
	.decorative-yellow {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

@media screen and (max-width: 767px) {
	.alert-pop-up-section {
		width: 450px;
	}

	.alert-content p {
		font-size: 1.05rem;
		line-height: 1.4rem;
	}
}

@media screen and (max-width: 479px) {
	.alert-pop-up-section {
		width: 265px;
	}

	.alert-content {
		margin: auto;
	}

	.alert-content p {
		font-size: 1rem;
		line-height: 1.4rem;
	}
}

/* Header alert */
.alert-header-section-wrapper {
	background-color: #296aab;
	display: block;
	overflow: hidden;
	display: none;
}

.aleart-header {
	color: #fff;
	justify-content: space-around;
	align-items: center;
	padding-top: 0;
	display: block;
	position: relative;
}

.alert-header-container {
	max-width: none;
	justify-content: center;
	align-items: center;
	padding-left: 3rem;
	padding-right: 3rem;
	display: flex;
	position: relative;
}

.alert-box-close-button {
	z-index: 500;
	width: 25px;
	height: 25px;
	float: right;
	color: rgba(255, 255, 255, 0.8);
	letter-spacing: 0.05em;
	text-transform: uppercase;
	cursor: pointer;
	background-image: url("/assets/images/exit-icon-white.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	border-bottom-style: none;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0%;
	padding-left: 0;
	padding-right: 15px;
	font-size: 0.75rem;
	line-height: 1.5em;
	text-decoration: none;
	transition: all 0.3s;
	position: absolute;
	top: auto;
	right: 3rem;
	border: 0px;
	background-color: transparent;
}

.alert-header-content-wrapper {
	padding: 0.75rem 0;
	font-size: 0.875em;
	display: flex;
	position: relative;
	width: 100%;
}

.alert-header-text {
	flex-grow: 1;
	flex-shrink: 1;
	align-self: center;
	align-items: center;
	display: flex;
	position: relative;
}

.alert-header-text .cms-website-content-region {
	width: calc(100% - 30px);
}

.alert-header-text p {
	color: #fff;
	letter-spacing: 0.5px;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.alert-header-text a {
	color: #fff;
	letter-spacing: 0.5px;
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
	transition: border-color 0.35s;
	text-decoration: none;
}

.alert-header-text a:hover {
	border-bottom-color: #fafafa;
}

@media screen and (max-width: 991px) {
	.alert-header-container {
		border: 1px #000;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.alert-box-close-button {
		margin-right: 0;
		padding-right: 0;
		right: 2rem;
	}

	.alert-header-content-wrapper {
		align-items: stretch;
		margin-left: 0;
		margin-right: 0;
		padding: 0.75rem 0;
	}
}

@media screen and (max-width: 767px) {
	.alert-header-container {
		justify-content: space-between;
		padding-left: 1.5rem;
		padding-right: 0;
	}

	.alert-box-close-button {
		margin-right: 0;
		right: 1.5rem;
	}

	.alert-header-content-wrapper {
		margin-left: 0;
		margin-right: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		font-size: 1em;
	}

	.alert-header-text {
		padding-right: 0;
	}

	.alert-header-text p {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 479px) {
	.alert-header-container {
		align-items: center;
		padding-left: 1rem;
	}

	.alert-box-close-button {
		margin-top: 0;
		right: 1rem;
	}

	.alert-header-content-wrapper {
		flex-wrap: wrap;
		margin-right: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		display: block;
		margin-right: 2rem;
	}

	.alert-header-text {
		flex-direction: column;
		flex-shrink: 0;
		flex-basis: 100%;
		align-self: flex-start;
		align-items: flex-start;
		padding-right: 0;
	}

	.alert-header-text p {
		margin-bottom: 0.5rem;
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.alert-header-text .cms-website-content-region p {
		margin-bottom: 0px;
	}
}
