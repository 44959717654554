/*************** Pagination ****************************/
/*******************************************************/
.pagination-wrapper {
	font-size: 0.9375rem;
	padding: 5px;
}

.pagination-wrapper .pagination-page-selection-top {
	width: 50%;
	text-align: left;
}

.pagination-wrapper .pagination-current-items-top {
	float: right;
}

.pagination-wrapper .pagination-current-page select,
.pagination-wrapper .pagination-items-per-page select {
	width: auto !important;
	max-width: 100%;
	height: auto;
	margin-bottom: 0px;
	padding: 5px 10px;
	display: inline;
}

.pagination-wrapper span {
	color: #737373 !important;
}

/********************* Accounts *************************/
/*******************************************************/
#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
}

#account_module.form .ui-form-field.required .ui-form-label em {
	color: #ee0000;
}

#account_module.form .form_item .label label {
	display: inline;
}

#account_module.form .form_item.required .field,
#account_module.form .form_item .field {
	background: none;
	padding-left: 10px;
	padding-right: 0px;
	padding-bottom: 10px;
}

#account_module.form .required_message span {
	background: none;
}

#account_module.form .form_item.required .label:after,
#account_module.form .required_message span:before {
	content: '*';
	color: #ee0000;
	padding-left: 5px;
	font-style: italic;
	font-weight: 400;
}

#account_module.form .required_message span:before {
	padding-right: 10px;
}

@media (min-width: 991px) {
	#account_module.form .form_container .login_field {
		width: 228px;
	}

	#account_module.form .more_options ul {
		padding-left: 0;
		padding-right: 240px;
		width: 450px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: auto;
	}

	#account_module.form .form_item .field,
	#account_module.form .form_item.required .field {
		padding-left: 0px;
		background: none;
		display: block;
		display: block;
		float: none;
		position: static;
	}

	#account_module.form .ui-form-fields {
		padding-left: 0px;
	}

	#account_module.form .ui-form-field .ui-form-input {
		display: block;
	}

	#account_module.form .ui-form-field .ui-form-label {
		height: auto;
		left: 0px;
		line-height: 1.125rem;
		padding: 6px 6px 6px 0;
		position: static;
		text-align: left;
		top: 6px;
		width: 100%;
	}
}

/****************** Search ************************/
/*************************************************/
.collection.search-collection > .node.search-node {
	border-top: #cccccc solid 1px;
	margin: 1rem 0px 0 0;
	padding: 1.3rem 0 0 0;
}

/****************** Content Modules ***************/
/*************************************************/
/* news */
#news_module.cms_list .cms_list_item .cms_title h3,
#news_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0;
	font-size: 1.15rem;
}

#news_module.cms_list .cms_list_item .cms_date {
	width: 33%;
}

#news_module.cms_list .cms_item_divider {
	margin-top: 16px;
	margin-bottom: 24px;
}

#news_module.cms_entity .cms_item_divider {
	display: inline-block;
	width: 100%;
}

@media (max-width: 991px) {
	#news_module.cms_list .cms_list_item table td,
	#news_module.cms_list .cms_list_item.left,
	#news_module.cms_list .cms_list_item.right {
		display: block;
		width: 100%;
		padding-left: 0px;
	}

	#news_module.cms_list .cms_list_item .cms_date h3 {
		font-size: 1.2rem;
	}
}

/* partners */
#partners_module.cms_list .cms_item_divider {
	margin-bottom: 24px;
	margin-top: 20px;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image img {
	max-width: none;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border: 0px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */
#photoalbums_module.cms_list {
	margin-top: 0px;
}

#photoalbums_module.cms_list .cms_list_item {
	display: inline-block;
	width: 100%;
	padding-bottom: 20px;
	margin-top: 22px;
	border-bottom: 1px solid #e1e3e0;
}

#photoalbums_module.cms_list .cms_list_item:first-child {
	margin-top: 0px;
}

#photoalbums_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image img {
	max-width: none;
}

#photoalbums_module.cms_list .cms_list_item .separator {
	line-height: 0;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* posts */
.collection.posts-collection .node.posts-node {
	border-top: 1px solid #e1e3e0;
	padding-bottom: 17px;
	padding-top: 26px;
	margin-top: 0px;
}

.collection.posts-collection .node.posts-node:first-child {
	padding-top: 0px;
	border-top: 0px;
}

.collection.posts-collection .node.posts-node .field.posts-field h2 {
	margin-top: 0px;
}

.collection.posts-collection .node.posts-node .field.posts-field img,
.node.posts-node img {
	width: auto;
	max-height: none;
}

.node.posts-node .post-comment {
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e1e3e0;
}

.posts-node .posts-comments .ui-form-legend {
	height: auto;
	background: none;
}

.posts-node .posts-comments .ui-form-fields {
	padding-left: 0px;
}

.posts-node .posts-comments .ui-form-field {
	display: block;
	float: none;
}

/* resources */
#moduleDocuments_module.cms_list .cms_items h4 {
	margin-top: 0.2rem;
}

#moduleDocuments_module.cms_list .cms_items h4 a {
	margin-left: 0px;
}

#moduleDocuments_module.cms_list .cms_title .icon {
	margin-bottom: 4px;
}


#moduleDocuments_module.cms_list .cms_description {
	background-position: 0px 6px;
}

#moduleDocuments_module.cms_list .cms_description {
	padding-left: 25px;
}

/* staff directory */
#staffdirectory_module.cms_list .cms_list_subheading {
	color: #002a54;
	font-family: "Roboto Condensed", sans-serif;
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 1.875rem;
	margin-bottom: 0.75rem;
}

#staffdirectory_module.cms_list .cms_item_divider {
	margin-bottom: 24px;
	margin-top: 20px;
}

#staffdirectory_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item table td.image img {
	max-width: none;
}

#staffdirectory_module.cms_entity .cms_metadata2.cms_title {
	clear: both;
}


@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#staffdirectory_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		display: block;
		margin-right: 0px;
		float: none;
	}
}

/* submission forms */
#submissionforms_module.cms_form .cms_buttons {
	margin-left: 3px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	color: #333;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	font-weight: normal;
}

#submissionforms_module.cms_form .sigNav {
	bottom: 28px;
	margin-left: 267px;
	position: relative;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"] {
	font-size: 0.8125rem;
}

#submissionforms_module .pad {
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigWrapper {
	display: block !important;
}

#submissionforms_module.cms_form .cms_required em,
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .cms_divider {
		display: none;
	}

	#submissionforms_module.cms_form .cms_label {
		display: inline-block;
		padding: 0;
		position: relative;
		text-align: left;
		width: auto;
	}

	#submissionforms_module.cms_form tr[data-required="true"] .cms_label::after {
		content: '*';
		color: #ee0000;
		padding-left: 5px;
		font-style: italic;
		font-weight: 400;
	}

	#submissionforms_module.cms_form .cms_required {
		display: none;
	}

	#submissionforms_module.cms_form .cms_field {
		display: block;
		padding: 6px 0 0;
		width: 100%;
	}

	.module-forms.confirmation table td {
		display: block;
		width: 100%;
	}
}

@media screen and (max-width: 479px) {
	#submissionforms_module.cms_form .checkbox_wrapper > table > tbody > tr > .checkbox-cell {
		display: inline;
	}
}

@media screen and (max-width: 380px) {
	#submissionforms_module.cms_form .sigNav {
		bottom: auto;
		float: right;
		margin-left: 0px;
		position: relative;
		margin-right: 18px;
		margin-top: -24px;
	}
}

/* date picker */
.ui-datepicker .ui-datepicker-title select,
.ui-datepicker td span,
.ui-datepicker td a,
.ui-datepicker th {
	font-size: 0.75rem;
}


/*************** Dynamic content boxes styling ***************/
/******* News *******/
#news_module.homepage_contentbox .cms_list_item {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #e1e8ea;
}

#news_module.homepage_contentbox .cms_list_item h3 {
	margin: 0.375em 0px;
	padding: 0;
	background-image: none;
	font-size: 1rem;
	line-height: 1.5em;
}

#news_module.homepage_contentbox .cms_list_item h3 a {
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #283f6f;
	text-decoration: none;
	cursor: pointer;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox .cms_list_item h3 a:hover {
	color: #9b5ba4;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	font-weight: 700;
	color: #333333;
	font-size: 0.875rem;
	font-family: Roboto, sans-serif;
}

#news_module.homepage_contentbox .cms_list_item .cms_description {
	margin: 0;
	color: #333333;
	font-size: 0.875rem;
	line-height: 1.5em;
}

#news_module.homepage_contentbox > a {
	display: none;
}

/******* Partners *******/
#partners_module .cycle-slideshow-carousel .partner-carousel-slide {
	padding: 0 15px;
}

.our-company-section-content-wrap #partners_module a {
	color: #fff;
}

/******* Submission forms *******/
#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
	font-weight: 400;
}

#submissionforms_module.homepage_contentbox .cms_label em {
	display: none;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}


@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}
